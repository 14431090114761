import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { AccountValueChart } from "../../Components/AccountValueChart";
import { ProfitTickerChart } from "../../Components/ProfitTickerChart";
import { ProfitCallPutChart } from "../../Components/ProfitCallPutChart";
import { TotalTrades } from "../../Components/TotalTrades";
import { WinPercentage } from "../../Components/WinPercentage";
import { DateRange } from "../../Components/DateRange";
import { dummyData } from "../../Utils/dummy";
import TotalProfitLoss from "../../Components/TotalProfitLoss";
import TradeByCallPut from "../../Components/TradeByCallPut";
import TradeByTicker from "../../Components/TradeByTicker";
import TradeBySell from "../../Components/TradeBySell";
import { tradeReportAction } from "./Action";
import dayjs from "dayjs";
import { Spin, notification } from "antd";

export const Dashboard = () => {
  const [tradeData, setTradeData] = useState({});
  const [loading, setloading] = useState(false);
  const [initalpayload, setinitalpayload] = useState({
    from_date_time: dayjs().subtract(1, "month"),
    to_date_time: dayjs(),
    option_type: "",
    ticker: "",
  });

  // const fetchData = async () => {
  //   // console.log(dayjs(initalpayload.from_date_time).format('DD MMM YYYY'))
  //   setloading(true);
  //   await tradeReportAction({ payload: initalpayload })
  //     .then(({ error, message, data }) => {
  //       if (!error) {
  //         setTradeData(data);
  //       } else {
  //         notification.error({ message });
  //         setTradeData({});
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setloading(false);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [initalpayload]);

  const noData = Object.keys(tradeData).length === 0;

  return (
    <div class="h-screen flex flex-col items-center justify-center p-6 text-center">
      <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-white">
        Trade Dashboard Coming Soon
      </h2>
    </div>
    // <div className="mt-5">
    //   <Spin tip="Loading..." spinning={loading} className="spinner ">
    //     <div class="dash_container mt-20">
    //       <div class="date-range  dark:border-dark bg-border_light dark:bg-border_dark">
    //         <DateRange
    //           initalpayload={initalpayload}
    //           setinitalpayload={(val) => setinitalpayload(val)}
    //         />
    //       </div>

    //       {/* Total Trades */}
    //       <div class="total-trades  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <TotalTrades trades={tradeData?.trade_by_bot} />
    //       </div>

    //       {/* WinPercentage */}
    //       <div class="win-percentage  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <WinPercentage winRate={tradeData?.win_rate} />
    //       </div>

    //       {/* Total Profit Loss */}
    //       <div class="total-profit-loss  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <TotalProfitLoss profitLoss={tradeData?.profit_loss} />
    //       </div>

    //       {/* Trade By Call/Put  */}
    //       <div class="trade-by-callput  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <TradeByCallPut
    //           initalpayload={initalpayload}
    //           setinitalpayload={(val) => setinitalpayload(val)}
    //           data={[
    //             {
    //               name: "Call",
    //               value: tradeData?.total_Call,
    //               color: "#2962FF",
    //             },
    //             {
    //               name: "Put",
    //               value: tradeData?.total_put,
    //               color: "#5E8DFF",
    //             },
    //           ]}
    //         />
    //       </div>

    //       {/* Trade By Ticker */}
    //       <div class="trade-by-ticker  border-light dark:border-dark bg-border_light dark:bg-border_dark ">
    //         <TradeByTicker
    //           data={noData ? {} : tradeData?.total_trade_ticker_wise}
    //           initalpayload={initalpayload}
    //           setinitalpayload={(val) => setinitalpayload(val)}
    //         />
    //       </div>

    //       {/* Trade By Sell Type */}
    //       <div class="trade-by-sell-type  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <TradeBySell
    //           data={
    //             noData
    //               ? []
    //               : [
    //                   {
    //                     name: "Exit By TP",
    //                     value: tradeData?.exit_by_tp,
    //                   },
    //                   {
    //                     name: "Exit By SL",
    //                     value: tradeData?.exit_by_sl,
    //                   },
    //                   {
    //                     name: "Exit By FS",
    //                     value: tradeData?.exit_by_forcesell,
    //                   },
    //                 ]
    //           }
    //         />
    //       </div>

    //       {/* Account Value Chart */}
    //       <div class="account-value-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //         <AccountValueChart data={tradeData.curve} />
    //       </div>

    //       {/* Profits */}
    //       <div class="profits">
    //         <div class="profit-by-ticker-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //           <ProfitTickerChart
    //             data={
    //               noData
    //                 ? []
    //                 : tradeData?.tickers_profit_loss?.length < 2
    //                   ? [...tradeData?.tickers_profit_loss, {}, {}]
    //                   : tradeData?.tickers_profit_loss
    //             }
    //           />
    //         </div>
    //         <div class="profit-by-callput-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
    //           <ProfitCallPutChart
    //             data={
    //               noData
    //                 ? []
    //                 : [
    //                     {
    //                       "Call Profit": tradeData?.total_call_profit,
    //                     },
    //                     {
    //                       "Put Profit": tradeData?.total_put_profit,
    //                     },
    //                   ]
    //             }
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </Spin>
    // </div>
  );
  // ffffff
  // if (Object.keys(tradeData).length > 0) {
  //   return (
  //     <Spin tip='Loading...' spinning={loading}>
  //       <div class="dash_container">
  //         <div class="date-range  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <DateRange initalpayload={initalpayload}
  //             setinitalpayload={(val) => setinitalpayload(val)} />
  //         </div>

  //         {/* Total Trades */}
  //         <div class="total-trades  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <TotalTrades trades={tradeData?.trade_by_bot} />
  //         </div>

  //         {/* WinPercentage */}
  //         <div class="win-percentage  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <WinPercentage winRate={tradeData?.win_rate} />
  //         </div>

  //         {/* Total Profit Loss */}
  //         <div class="total-profit-loss  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <TotalProfitLoss profitLoss={tradeData?.profit_loss} />
  //         </div>

  //         {/* Trade By Call/Put  */}
  //         <div class="trade-by-callput  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <TradeByCallPut
  //             initalpayload={initalpayload}
  //             setinitalpayload={(val) => setinitalpayload(val)}
  //             data={[
  //               {
  //                 name: "Call",
  //                 value: tradeData?.total_Call,
  //                 color: "#2962FF",
  //               },
  //               {
  //                 name: "Put",
  //                 value: tradeData?.total_put,
  //                 color: "#5E8DFF",
  //               },
  //             ]}
  //           />
  //         </div>

  //         {/* Trade By Ticker */}
  //         <div class="trade-by-ticker  border-light dark:border-dark bg-border_light dark:bg-border_dark ">
  //           <TradeByTicker
  //             data={tradeData?.total_trade_ticker_wise}
  //             initalpayload={initalpayload}
  //             setinitalpayload={(val) => setinitalpayload(val)}
  //           />
  //         </div>

  //         {/* Trade By Sell Type */}
  //         <div class="trade-by-sell-type  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <TradeBySell
  //             data={[
  //               {
  //                 name: "Exit By TP",
  //                 value: tradeData?.exit_by_tp,
  //               },
  //               {
  //                 name: "Exit By SL",
  //                 value: tradeData?.exit_by_sl,
  //               },
  //               {
  //                 name: "Exit By FS",
  //                 value: tradeData?.exit_by_forcesell,
  //               },
  //             ]}
  //           />
  //         </div>

  //         {/* Account Value Chart */}
  //         <div class="account-value-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //           <AccountValueChart />
  //         </div>

  //         {/* Profits */}
  //         <div class="profits">
  //           <div class="profit-by-ticker-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //             <ProfitTickerChart
  //               data={
  //                 tradeData?.tickers_profit_loss.length < 2
  //                   ? [...tradeData?.tickers_profit_loss, {}, {}]
  //                   : tradeData?.tickers_profit_loss
  //               }
  //             />
  //           </div>
  //           <div class="profit-by-callput-chart  border-light dark:border-dark bg-border_light dark:bg-border_dark">
  //             <ProfitCallPutChart
  //               data={[
  //                 {
  //                   "Call Profit": tradeData?.total_call_profit,
  //                 },
  //                 {
  //                   "Put Profit": tradeData?.total_put_profit,
  //                 },
  //               ]}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </Spin>
  //   );
  // } else {
  //   return <div>No Data Found</div>;
  // }
};
//

import { Card, ConfigProvider, Table } from "antd";
import "./App.css";
import Layout from "./Utils/Layout";
import { antd_dark_theme } from "./Utils/dummy";
import { is_Dark_Theme } from "./Utils/setting";
import { createContext, useState } from "react";

export const logedInUser = createContext();

function App() {
  const is_dark_theme = is_Dark_Theme();
  const [userData, setUserData] = useState({});
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3A46C6",
          colorBgContainer: is_dark_theme ? "#2A2E39" : "#F0F3FA",
          colorText: is_dark_theme ? "#080808" : "#131722",
          colorTextPlaceholder: is_dark_theme ? "#D1D4DC" : "#131722",
          colorIcon: is_dark_theme ? "#D1D4DC" : "#131722",
          colorIconHover: is_dark_theme ? "#D1D4DC" : "#131722",
        },
        // components: {
        //   Checkbox: {
        //     colorBgTextActive: 'red'
        //   }
        // }
      }}
    >
      <logedInUser.Provider value={{userData, setUserData}}>
        <Layout />
      </logedInUser.Provider>
    </ConfigProvider>
  );
}

export default App;

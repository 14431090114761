import { ConfigProvider, Switch, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "../Screens/Auth/Login";
import RequestResetLink from "../Screens/Auth/RequestResetLink";
import ResetPassword from "../Screens/Auth/ResetPassword";
import SignUp from "../Screens/Auth/SignUp";
import { Dashboard } from "../Screens/Dashboard/Dashboard";
import Subscription from "../Screens/Subscription/Subscription";
import SubscriptionDetails from "../Screens/Subscription/subscriptionDetails";
import { logoutAction } from "./apiAction";
import "./layout.css";

import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { logedInUser } from "../App";
import DARK_LOGO from "../Assets/dark_logo.png";
import LIGHT_LOGO from "../Assets/light_logo.png";
import PrivacyPolicy from "../Screens/Privacy/PrivacyPolicy";
import TermsServices from "../Screens/Privacy/TermsServices";

export default function Layout() {
  const location = useLocation();
  console.log(location.pathname);
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const isLoggedIn = !!userToken; // Convert userToken to a boolean
  // const loggedInUser = JSON.parse(localStorage.getItem("loginUser"));
  const cacheTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(cacheTheme ? cacheTheme : "light");

  const [menuExpand, setMenuExpand] = useState(false);

  const { userData, setUserData } = useContext(logedInUser);

  const authRoutes =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/reset_password" ||
    location.pathname === "/set_new_password" ||
    location.pathname === "/terms-of-services" ||
    location.pathname === "/privacy-policy";

  useEffect(() => {
    if (!isLoggedIn && !authRoutes) {
      navigate("/login", { replace: true });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    const temp = theme === "light" ? "dark" : "light";
    setTheme(temp);
    localStorage.setItem("theme", temp);
  };

  async function onLogout() {
    await logoutAction()
      .then(({ error, message }) => {
        if (!error) {
          localStorage.clear();
          setUserData({});
          navigate("/login", { replace: true });
        } else {
          if (message === "Signature has expired.") {
            notification.error({ message: "Session TimeOut Logging out User" });

            localStorage.clear();
            navigate("/login", { replace: true });
          } else notification.error({ message });
        }
      })
      .catch((error) => console.log(error));
  }

  const antdLight = {
    // Seed Token
    colorPrimary: "#2962FF",
    borderRadius: 2,

    // Alias Token
    colorBgContainer: "#F0F3FA",
  };

  const antdDark = {
    // Seed Token
    colorPrimary: "#2962FF",
    borderRadius: 2,

    // Alias Token
    colorBgContainer: "#F0F3FA",
  };

  const closeMenu = () => {
    setMenuExpand(false);
  };

  return (
    <ConfigProvider
      theme={{
        token: theme === "light" ? antdLight : antdDark,
        components: {
          DatePicker: {
            borderRadius: 5,
          },
        },
      }}
    >
      <div
        className={`h-screen w-screen bg-white dark:bg-dark  font-sans text-light dark:text-dark flex flex-col items-center overflow-x-hidden p-0  `}
      >
        <div className="mobile-nav-ctr">
          <div className="mobile-nav bg-white dark:bg-dark">
            <img
              src={theme === "light" ? DARK_LOGO : LIGHT_LOGO}
              alt="logo"
              className="mobile-app_logo "
            />

            {menuExpand ? (
              <CloseOutlined
                className="mobile-app_menu"
                onClick={() => setMenuExpand((e) => !e)}
              />
            ) : (
              <MenuOutlined
                className="mobile-app_menu"
                onClick={() => setMenuExpand((e) => !e)}
              />
            )}
          </div>

          <div
            className={`drawer-menu-ctr ${
              menuExpand && "drawer-expand"
            }  bg-white dark:bg-dark`}
          >
            {!authRoutes && (
              <>
                <Link
                  onClick={closeMenu}
                  to={"/dashboard"}
                  className="menu-item"
                >
                  DashBoard
                </Link>
                <Link
                  onClick={closeMenu}
                  to={"/subscription"}
                  className="menu-item"
                >
                  Subscription
                </Link>
                <Link
                  onClick={closeMenu}
                  to={"/subscription-details"}
                  className="menu-item"
                >
                  My Subscription
                </Link>
                <Link
                  onClick={closeMenu}
                  to={"/plan-action"}
                  className="menu-item"
                >
                  Action
                </Link>
                <div
                  className="menu-item bg-primary_button"
                  onClick={() => {
                    closeMenu();

                    if (isLoggedIn) {
                      onLogout();
                    } else {
                      navigate("/login", { replace: true });
                    }
                  }}
                >
                  {isLoggedIn ? "SignOut" : "Login / SignUp"}
                </div>
              </>
            )}

            <div
              className="menu-item"
              onClick={() => {
                handleThemeSwitch();
                closeMenu();
              }}
            >
              {theme === "dark" ? "Light Mode" : "Dark Mode"}
            </div>
          </div>
        </div>

        <div
          className={`w-[calc(100%-5px)] flex items-center justify-center h-20 fixed top-0 pc-nav z-10 backdrop-blur-lg backdrop-filter bg-opacity-30 bg-primary`}
        >
          <img
            src={theme === "light" ? DARK_LOGO : LIGHT_LOGO}
            alt="logo"
            className="app_logo "
          />

          {!authRoutes && (
            <div className=" absolute flex flex-row  justify-between text-xl">
              <Link
                to={"/dashboard"}
                className={`lg:mx-2 xl:mx-14  hover:text-highlight font-bold  ${
                  location.pathname === "/dashboard" && "text-highlight"
                }`}
              >
                Dashboard
              </Link>
              <Link
                to={"/subscription"}
                className={`lg:mx-2 xl:mx-14 hover:text-highlight font-bold  ${
                  location.pathname === "/subscription" && "text-highlight"
                }`}
              >
                Subscription
              </Link>
              <Link
                to={"/subscription-details"}
                className={`lg:mx-2 xl:mx-14 hover:text-highlight font-bold  ${
                  location.pathname === "/subscription-details" &&
                  "text-highlight"
                }`}
              >
                My Account
              </Link>
            </div>
          )}

          <div className="flex flex-row items-center justify-center absolute right-8">
            {!authRoutes && (
              <button
                onClick={() => {
                  if (isLoggedIn) {
                    onLogout();
                  } else {
                    navigate("/login", { replace: true });
                  }
                }}
                type="submit"
                class="flex w-32 bg-primary_button justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-button_primary wider mr-9"
              >
                {isLoggedIn ? "Logout" : "Login / SignUp"}
              </button>
            )}

            <Switch
              className="mr-5"
              checked={theme === "dark"}
              style={{
                backgroundColor: theme === "light" ? "#8C8C8C" : "#00a654",
              }}
              onChange={handleThemeSwitch}
              checkedChildren="Dark"
              unCheckedChildren="Light"
            />
          </div>
        </div>

        <Routes>
          {/* {!isLoggedIn && redirect('/login')} */}
          <Route
            path="*"
            element={<Navigate to="/dashboard" replace={true} />}
          />
          <Route
            path="/"
            element={<Navigate to="/dashboard" replace={true} />}
          />

          {/* AuthRoutes */}
          <Route path="/login" element={<Login />} />
          <Route path="/reset_password" element={<RequestResetLink />} />
          <Route path="/set_new_password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />

          {/* Other Routes */}
          <Route
            path="/subscription-details"
            element={<SubscriptionDetails />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/terms-of-services" element={<TermsServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/plan-action" element={<PlanAction />} /> */}
        </Routes>

        {/* <p>Createwd</p> */}
      </div>
    </ConfigProvider>
  );
}

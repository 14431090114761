import React from "react";

const PrivacyPolicy = () => {
  const header = `text-xl font-bold mt-5`;
  return (
    <div class="flex flex-col px-6  lg:px-8 mt-20 w-[80%] mb-10 text-justify">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Trade Master LLC Privacy Policy
      </h2>
      Welcome to Trade Master LLC, where we are committed to safeguarding your
      privacy and ensuring the security of your personal information. This
      comprehensive Privacy Policy details how we collect, use, disclose, and
      protect your data in connection with our trading bot software and related
      services.
      <h3 className={`${header}`}>1. Information We Collect:</h3>
      In order to provide you with the best possible trading experience, we
      collect various types of information, including:
      <br />
      <br />
      1: Personal Information: This encompasses details such as your name, email
      address, contact information, payment details, and other necessary
      identifiers for registration and billing purposes.
      <br />
      2: Trading Data: We may collect information about your trading activities,
      preferences, transaction history, and interactions with our trading bot
      software. This data helps us tailor our services to your individual needs
      and preferences.
      <br />
      3: Technical Information: This includes log data, device information, IP
      addresses, browser type, and other technical details that are
      automatically collected when you use our platform. We utilize this
      information to optimize performance, diagnose technical issues, and
      enhance security.
      <br />
      <h3 className={`${header}`}>2. Use of Your Information:</h3>
      We leverage the information we collect for a variety of purposes,
      including but not limited to:
      <br />
      <br />
      1: Improving Our Services: By analyzing user behavior and feedback, we
      continuously refine and enhance our trading bot software to better meet
      the needs of our users.
      <br />
      2: Customization: We personalize your trading experience by offering
      tailored recommendations, alerts, and insights based on your trading
      history and preferences.
      <br />
      3: Security Measures: Your data is crucial to us, and we employ robust
      security measures to protect it from unauthorized access, misuse, or
      alteration. We regularly update our security protocols to ensure the
      highest level of protection.
      <br />
      <h3 className={`${header}`}>3. Data Sharing and Disclosure:</h3>
      We respect your privacy and do not sell or rent your personal information
      to third parties for marketing purposes. However, there are instances
      where we may share your data:
      <br />
      <br />
      1: Service Providers: We may engage trusted third-party service providers
      to assist us in delivering our services, processing transactions, or
      conducting market research. These service providers are contractually
      obligated to uphold the same standards of data protection as we do.
      <br />
      2: Legal Compliance: We may disclose your information if required by law,
      regulatory authorities, or legal proceedings to protect our rights,
      property, or safety, or to comply with applicable laws and regulations.
      <br />
      <h3 className={`${header}`}>4. Your Rights and Choices:</h3>
      At Trade Master LLC, we believe in empowering our users to control their
      personal information. Therefore, you have the right to:
      <br />
      <br />
      1: Access and Update: You can access and update your personal information
      stored in our systems at any time through your account settings or by
      contacting our customer support team.
      <br />
      2: Opt-Out: You have the option to opt-out of receiving promotional
      communications from us by following the unsubscribe instructions provided
      in our emails or by contacting us directly.
      <br />
      <h3 className={`${header}`}>5. Children's Privacy:</h3>
      Our trading bot software and services are intended for individuals who are
      18 years of age or older. We do not knowingly collect personal information
      from minors, and if we become aware that we have inadvertently obtained
      such information, we will take prompt steps to delete it from our records.
      <h3 className={`${header}`}>6. Changes to This Privacy Policy:</h3>
      As our business evolves and regulations change, we may update this Privacy
      Policy periodically. We will notify you of any material changes by posting
      the revised policy on our website or through other appropriate channels.
      Your continued use of our services following such changes constitutes your
      acceptance of the updated Privacy Policy.
      <h3 className={`${header}`}>7. Contact Us:</h3>
      If you have any questions, concerns, or requests regarding this Privacy
      Policy or the handling of your personal information, please don't hesitate
      to contact us at support@tradewithmaster.com. Our dedicated team is here
      to assist you and address any inquiries you may have.
      <br />
      <br />
      By utilizing our trading bot software and services, you acknowledge and
      consent to the collection, use, and disclosure of your personal
      information in accordance with this Privacy Policy.
      <br />
      <br />
      Last updated: 03/20/2024
    </div>
  );
};

export default PrivacyPolicy;

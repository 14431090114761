import { Button, Form, Input, notification } from 'antd';
import React from 'react';
import { validateEmail } from '../../Utils/setting';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { resetPasswordRequestAction } from './Action';

export default function RequestResetLink() {
  const onFinish = (values) => {

    resetPasswordRequestAction({ email: values.email })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({
            message:
              'Password reset link sent successfully. Please check your email.',
          });
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  return (
    <div class="flex min-h-full w-96 flex-col px-6  lg:px-8 mt-32">
      <ExclamationCircleOutlined className=' text-5xl mb-5 self-center' />
      <h1 className='text-xl font-bold leading-tight tracking-tight text-center'>
        Forgot Your Password?
      </h1>

      <p className='w-80 text-xs my-3 text-center mb-10'>
        Please provide your email address, and we will promptly send you a link
        to reset your password.
      </p>

      <Form onFinish={onFinish} autoComplete='off' className="font-sans text-light dark:text-dark">
        {/* <p className=' text-primary text'>Email:</p> */}

        <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>

            <Form.Item name={"email"} rules={[
            {
              required: true,
              message: 'Please enter your email!',
            },
            {
              validator: validateEmail,
            },
          ]}>
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block mt-2 w-full rounded-md border-0 p-1.5 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
              />
            </Form.Item>
          </div>

        <Form.Item>
        <button
              type="submit"
              class="flex w-full bg-primary_button justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-button_primary mt-5"
            >
              Request Link
            </button>
        </Form.Item>
      </Form>
    </div>
  );
}

import { Checkbox, Form, Input, Spin, notification } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signUpUserAction } from "./Action";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SmileFilled,
} from "@ant-design/icons";

export default function SignUp() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  //f
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((e) => !e);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((e) => !e);
  };

  const validateEmail = (rule, value, callback) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(value)) {
      callback(); // Validation passed
    } else {
      callback("Invalid email format"); // Validation failed
    }
  };

  const validatePassword = (rule, value, callback) => {
    const password = value;
    const confirmPassword = form.getFieldValue("confirmPassword"); // Get the value of the Confirm Password field
    if (password && confirmPassword && password !== confirmPassword) {
      callback("Passwords do not match");
    } else {
      callback();
    }
  };

  function onFinish(values) {
    if (!values.termsOfService)
      return notification.error({
        message: "Please accept the terms of service",
      });

    const payload = {
      email: values.email,
      username: values.username,
      firstname: values.firstname,
      lastname: values.lastname,
      password: values.password,
    };

    signUpUserAction({ payload })
      .then(({ error, message }) => {
        if (error) notification.error({ message: message });
        else {
          api.open({
            message: "SignUp Successful",
            description:
              "User registration was successful. Please check your inbox to verify the user.",
            key: "asdf",
            icon: <SmileFilled className="text-Success" />,
          });

          setTimeout(() => {
            api.destroy("asdf");
            navigate("/Login", { replace: true });
          }, 3000);
        }
      })
      .catch((error) => {
        notification.error({ message: `Sign up failed: ${error}` });
      });
  }

  return (
    <>
      {contextHolder}
      <div class="flex min-h-full w-[28rem] flex-col px-6  lg:px-8 mt-20">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create your account
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600 tracking-wide">
            Start your bot journey today!
          </p>
        </div>

        <Form
          onFinish={onFinish}
          autoComplete="off"
          className="font-sans text-light dark:text-dark mt-8 "
          form={form}
        >
          <div className="flex justify-between">
            <Form.Item
              name="firstname"
              rules={[{ required: true, message: "Please Enter Username" }]}
            >
              <input
                placeholder="First Name"
                type="text"
                class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
              />
            </Form.Item>
            <Form.Item
              name="lastname"
              rules={[{ required: true, message: "Please Enter Username" }]}
            >
              <input
                placeholder="Last Name"
                type="text"
                class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
              />
            </Form.Item>
          </div>

          <Form.Item
            name={"email"}
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <input
              placeholder="Email"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
            />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please Enter Username" }]}
          >
            <input
              placeholder="Username"
              type="text"
              class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
            />
          </Form.Item>

          <div className="relative items-center content-center">
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
                // {
                //   validator: validatePassword, // Use the custom validator
                // },
              ]}
            >
              <input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                autocomplete="current-password"
                required
                class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
              />
            </Form.Item>
            {showPassword ? (
              <EyeInvisibleOutlined
                onClick={toggleShowPassword}
                className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
              />
            ) : (
              <EyeOutlined
                onClick={toggleShowPassword}
                className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
              />
            )}
          </div>
          {/*  */}
          <div className=" relative flex flex-row justify-center items-center">
            <Form.Item
              className="absolute left-0"
              name={"termsOfService"}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <p class="text-center text-sm text-gray-500 tracking-wider ml-5">
              By creating an account you agree to our <br />{" "}
              <Link
                to={"/terms-of-services"}
                target="_blank"
                class="text-highlight cursor-pointer"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to={"/privacy-policy"}
                target="_blank"
                class="text-highlight cursor-pointer"
              >
                Privacy Policy
              </Link>{" "}
            </p>
          </div>

          {/* <div className="relative items-center content-center">
            <Form.Item
              name={"confirmPassword"}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  validator: validatePassword, // Use the custom validator
                },
              ]}
            >
              <input
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                autocomplete="current-password"
                required
                class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
              />
            </Form.Item>
            {showConfirmPassword ? (
              <EyeInvisibleOutlined
                onClick={toggleShowConfirmPassword}
                className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
              />
            ) : (
              <EyeOutlined
                onClick={toggleShowConfirmPassword}
                className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
              />
            )}
          </div> */}

          <Form.Item>
            <button
              type="submit"
              class="w-full mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm py-3 px-3 text-center"
            >
              Sign Up
            </button>
          </Form.Item>
        </Form>
        {/*  */}

        {/* <p class="mt-2 text-center text-sm text-gray-500">
          Already an Existing User?{" "}
          <a
            onClick={() => navigate("/login")}
            className=" text-highlight font-semibold hover:cursor-pointer"
          >
            Sign in
          </a>
        </p> */}
      </div>
    </>
  );
}

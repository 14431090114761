import axios from 'axios';
import {
  cancelSubscriptionUrl,
  createSubscriptionUrl,
  getAllMySubscriptionsUrl,
  getAllSubscriptionUrl,
  getSubscriptionKeyUrl,
  pauseSubscriptionUrl,
  resumeSubscriptionUrl,
} from '../Api';
import { getToken } from '../../../Utils/setting';

export const getAllSubscriptionAction = () => {
  const tokenHeader = getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(getAllSubscriptionUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const createSubscriptionAction = ({ payload }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .post(createSubscriptionUrl, payload, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getAllMySubscriptionsAction = () => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .get(getAllMySubscriptionsUrl, tokenHeader)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const pauseSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .put(
        `${pauseSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        {},
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const resumeSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .put(
        `${resumeSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        {},
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const cancelSubscriptionAction = ({ sub_id }) => {
  const tokenHeader = getToken();

  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${cancelSubscriptionUrl}?stripe_subscription_id=${sub_id}`,
        tokenHeader
      )
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

import axios from 'axios';
import {
  getMeUrl,
  resetPasswordRequestUrl,
  setNewPasswordUrl,
  signInUrl,
  signUpUrl,
} from '../Api';

export const signUpUserAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(signUpUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const signInUserAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(signInUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const getMeAction = ({ token }) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ngrok-skip-browser-warning': 'true'
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(getMeUrl, options)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const resetPasswordRequestAction = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${resetPasswordRequestUrl}?email=${email}`)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

export const setNewPasswordAction = ({ payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(setNewPasswordUrl, payload)
      .then((response) => {
        resolve(response.data); // Resolve the Promise with the response data
      })
      .catch((error) => {
        reject(error); // Reject the Promise with the error
      });
  });
};

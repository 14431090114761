import { notification } from 'antd';

export const API_URL = process.env.REACT_APP_API_URL + '/api';

const userToken = JSON.parse(localStorage.getItem("userToken"));
export const isLoggedIn = !!userToken; // Convert userToken to a boolean


export const is_Dark_Theme = () => {
  return localStorage.getItem("theme") === 'dark'
}

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('userToken'));
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ngrok-skip-browser-warning': 'true',
    },
  };
};

// Enums
export const currencyEnums = {
  INR: 1,
  USD: 2,
};

export const billingCycleEnums = {
  MONTHLY: 1,
  YEARLY: 2,
  QUARTERLY: 3,
};

// Functions
export function getKeyByValue(object, targetValue) {
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] === targetValue) {
      return key;
    }
  }
  return null; // Return null if the value is not found
}

// Validators
export const validateEmail = (rule, value, callback) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(value)) {
    callback(); // Validation passed
  } else {
    callback('Invalid email format'); // Validation failed
  }
};

export const logoutUser = ({ navigate, error }) => {
  if (error?.response?.status === 401) {
    notification.error({ message: 'Session TimeOut Logging out User' });

    localStorage.clear();
    navigate('/Login', { replace: true });
  }
};

export function getRandomColor() {
  // Generate random values for red, green, and blue components
  var red = Math.floor(Math.random() * 256);
  var green = Math.floor(Math.random() * 256);
  var blue = Math.floor(Math.random() * 256);

  // Create a CSS-formatted color string
  var color = "rgb(" + red + "," + green + "," + blue + ")";

  return color;
}

import React from "react";

const TermsServices = () => {
  const header = `text-xl font-bold mt-5`;
  return (
    <div class="flex flex-col px-6  lg:px-8 mt-20 w-[80%] mb-10 text-justify">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        TRADE MASTER LLC TERMS AND CONDITIONS
      </h2>
      {/*  */}
      Welcome to Trade Master LLC! These detailed Terms and Conditions ("Terms")
      meticulously outline the terms of use for our esteemed trading bot
      services. By engaging with, purchasing, or simply accessing our innovative
      trading bot ("Service"), you, the valued user ("User," "you," or "your"),
      willingly agree to be bound by these comprehensive Terms.
      <br />
      <h3 className={`${header}`}>1. Agreement to Terms</h3>
      Upon accessing or utilizing the Service, you explicitly acknowledge and
      accept these Terms in their entirety. Should any portion of these Terms
      prove disagreeable to you, refrain from utilizing the Service. Please
      note, Trade Master retains the right to revise or modify these Terms at
      our discretion and without prior notification. Continual usage of the
      Service post any amendments constitutes acknowledgment and acceptance of
      the updated Terms. <br />
      <h3 className={`${header}`}>2. Service Description </h3>
      Trade Master LLC prides itself on providing cutting-edge trading bot
      solutions. Our Service enables users to seamlessly integrate their
      Interactive Brokers (IBKR) accounts with our advanced trading bot
      platform. This sophisticated bot aids in trade management by furnishing
      users with an array of tools and analytical resources, empowering them to
      make well-informed trading decisions. It is imperative to understand that
      while our bot streamlines trade processes, it does not autonomously
      execute trades on behalf of users.
      <h3 className={`${header}`}> 3. User Eligibility </h3>
      By engaging with our Service, you affirm that you are at least 18 years of
      age and possess the legal capacity to enter into binding contracts. Should
      you be representing a company or other entity, you affirm that you have
      the requisite authority to bind said entity to these Terms.
      <h3 className={`${header}`}>4. Registration and Account Security </h3>
      Accessing our Service may necessitate registration for an account. During
      the registration process, you pledge to provide accurate, current, and
      complete information. It is your responsibility to maintain the
      confidentiality of your account credentials and to promptly notify Trade
      Master of any unauthorized account access or security breaches.
      <h3 className={`${header}`}>5. Service Usage</h3>
      Your use of our Service is contingent upon adherence to these Terms and
      all pertinent laws and regulations. You further agree: <br />
      1: Not to employ the Service for unlawful or fraudulent activities. <br />
      2: Not to solicit others to partake in unlawful actions via the Service.{" "}
      <br />
      3: Not to disrupt or impair the integrity or functionality of the Service.{" "}
      <br />
      4: Not to attempt unauthorized access to the Service or associated
      systems. <br />
      5: Not to engage in activities that could damage, overload, or hinder the
      Service. <br />
      <h3 className={`${header}`}>6. Risks and Disclaimers</h3>
      Trading inherently carries significant risks, a fact that users must
      comprehensively acknowledge and accept. By utilizing the Trade Master bot,
      you expressly recognize and consent to the following: <br />
      1: Trade Master LLC does not provide investment advice, nor does the
      utilization of our bot guarantee profits or safeguard against losses.{" "}
      <br />
      2: Users are solely accountable for their trading decisions and any
      ensuing financial losses. Trade Master LLC assumes no responsibility for
      any financial losses incurred due to bot usage or reliance on provided
      information. <br />
      3: Users are advised to meticulously assess their financial circumstances,
      investment objectives, and risk tolerances before utilizing the bot.{" "}
      <br />
      4: Historical performance is not indicative of future outcomes, and no
      assertions are made regarding potential profits or losses. <br />
      5: User engagement with the bot signifies acceptance of all associated
      risks, and Trade Master LLC shall bear no liability for resultant losses,
      damages, or expenses.
      <h3 className={`${header}`}>7. Intellectual Property Rights</h3>
      The Service, inclusive of all its contents, features, and functionalities,
      remains the proprietary asset of Trade Master LLC and is safeguarded by
      copyright, trademark, and additional intellectual property laws. Users are
      prohibited from modifying, reproducing, distributing, or creating
      derivative works based on the Service without prior written consent from
      Trade Master.
      <h3 className={`${header}`}>8. Limitation of Liability</h3>
      Under no circumstances shall Trade Master be held liable for indirect,
      incidental, special, consequential, punitive, or exemplary damages,
      inclusive of but not limited to loss of profits, data, or usage, arising
      from Service usage or these Terms, even if prior notification of such
      damages was provided to Trade Master.
      <h3 className={`${header}`}>9. Indemnification</h3>
      Users agree to indemnify, defend, and hold Trade Master, its affiliates,
      officers, directors, employees, agents, licensors, and suppliers harmless
      against all losses, expenses, damages, and costs, including reasonable
      attorney fees, resulting from any breach of these Terms or any activity
      related to the user's account.
      <h3 className={`${header}`}>10. Governing Law and Jurisdiction</h3>
      These Terms shall be governed by and construed in accordance with the laws
      of the State of Texas, without regard to its conflict of law principles.
      Any disputes arising out of or relating to these Terms shall be
      exclusively adjudicated in the state and federal courts located in [your
      county], Texas.
      <h3 className={`${header}`}>11. Entire Agreement</h3>
      This document constitutes the entire agreement between you and Trade
      Master LLC concerning the subject matter herein and supersedes all prior
      or contemporaneous agreements, whether oral or written.
      <h3 className={`${header}`}>12. Contact Information</h3>
      For any inquiries or concerns regarding these Terms or the Service, please
      feel free to contact us at support@tradewithmaster.com
      <br />
      <br />
      Upon accepting these Terms, you affirm that you have meticulously
      reviewed, comprehended, and consented to abide by all stipulations
      contained herein.
    </div>
  );
};

export default TermsServices;
